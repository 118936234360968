import React, { useState, useEffect }  from 'react';
import './Main.css'
import {Link, useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import PaginationOld from './PaginationOld';
import {db} from '../../firebase'
import Slider from '../inc/Slider';
import Navbar from '../inc/Navbar';
import AddBook from '../AddBook';
import Product1 from '../images/outdoor.jpg';
import Product2 from '../images/floortiles.jpg';
import Product3 from '../images/intr.jpg';
import Brand from '../images/brand.jpg';
import Bathroom from '../images/bath.jpg';
import Jaquare from '../images/jaquare.jpg';
import Kitchen from '../images/kitchen.jpg';
import Newti from '../images/newt.jpg';
import Saniraey from '../images/sani.jpg';
import SaniraeyWare from '../images/fonte.jpg';
import Bathroom2 from '../images/bath1.jpg';
import ScrollToTop from "react-scroll-to-top";
import Pdf from "../images/tileskajaria.pdf";
import Mapg from '../pages/Mapg';
import Bathh from '../images/jaa.jpg';
import ArcJaquare from '../images/Arc.jpeg';
import JaquareLaguna from '../images/Laguna.jpeg';
import JaquareBidaspa from '../images/Bidaspa.png';
import JContinental from '../images/Continental.jpeg';


function Main(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(3);
    const [pagination, setPagination] =useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start,  end) =>{
        setPagination({start: start, end: end});
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [allproducts, setAllproducts] = useState([])
    const navigate = useNavigate()

    const allproductsCollectionRef = collection(db, "allproducts");
    useEffect(() => {

        const getAllproducts = async () => {
            const data = await getDocs(allproductsCollectionRef);
            setAllproducts(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
        }

        getAllproducts()
    }, [])
    return (
<>
<Navbar/>
<div className="album py-0">
        
        <div className="container " >

            <div className="row ">
       
            <div className="col-md-5" >
              <h5> Clasik Tiles and Marbel</h5>
            </div>
            </div>
            </div>
            </div>
<br/>

<ScrollToTop smooth top="100" color="Gray"/>

<Slider/>
<br/>

<marquee><h2 style={{textAlign: "center", color: "purple"}}> <b>CLASIK TILES AND MARBEL ( KARIMPUR, NATIDANGAMORE )</b> </h2></marquee>

<br/>

<div class="p-3 mb-2 bg-dark text-white">

            
                    <h1><center>Our Brand</center></h1>
                           
                  
                    </div>
                    <div className="first">
                    <img src={Brand} class="img-fluid " width="1350" height="600"  alt="Brand"></img>
                    </div>

                        <hr/>            
<div className="album py-2">
        
        <div className="container " >

            <div className="row ">
       
            <div className="col-md-4 mt-8 " >
                        <div className="card mb-4 border-White box ">
                            <Link to="/out-door-tiles">
                        <img src={Product1} className="card-img-top"   alt="Product1" />
                        </Link>
                            <div className="card-body">
                                
                                <h6  style={{ color: "DarkSlateBlue	"}} className="card-text"> <b>Outdoor Floor Tiles</b></h6>
                                <Link to="/out-door-tiles">
                                <p className="card-text">Explore Now</p>
                        
                                <div className="d-flex justify-content-between align-items-center">
                               
                                       
                        
                              
                            </div>
                            </Link>
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-4 mt-6 " >
                        <div className="card mb-4 border box  ">
                          <Link to="/Floor">
                        <img src={Product2} className="card-img-top "   alt="Product2" /></Link>
                            <div className="card-body">
                                <h6 style={{ color: "DarkSlateBlue	"}} className="card-text"><b>Living Room floor Tiles</b></h6>
                                <Link to="/Floor">
                                <p className="card-text"> Explore Now</p>
<div className="d-flex justify-content-between align-items-center">
                                
                           
                            </div>
                            </Link>
                            </div>
                            
                            </div>
                               
                            </div>
                            {/* style={{textAlign: "center"}} */}
                            <div className="col-md-4" >
                        <div className="card mb-4 border box">
                          <Link to="/wall">
                        <img src={Product3} className="card-img-top"   alt="Product3" /></Link>
                            <div className="card-body">
                                <h6 style={{ color: "DarkSlateBlue		"}} className="card-text"><b>Living Room Wall Tiles</b></h6>
                                <Link to="/wall">
                                <p   className="card-text">Explore Now</p>
                                <div className="d-flex justify-content-between align-items-center">
                                   
                               
                               
                           
                      
                                     
                                      
                                       
                              
                            </div>
                            </Link>
                            </div>
                            </div>
                               
                            </div>
                            </div>
                            </div>
                            </div>
                            <img src={Bathh} class="img-fluid" width="1350" height="600"  alt="Outdoor"></img>


<div className="album py-4">
        
        <div className="container " >

            <div className="row ">
       
            <div className="col-md-3 mt-8 " >
                        <div className="card mb-4 border-White box ">
                      
                        <img src={ArcJaquare} className="card-img-top"   alt="ArcJaquare" />
                    
                            <div className="card-body">
                                
                                <h5  style={{textAlign: "center",color: "Gray	"}} className="card-text">Arc</h5>
                                <Link to="/outd">
                       
                             
                        
                                <div className="d-flex justify-content-between align-items-center">
                               
                                       
                        
                              
                            </div>
                            </Link>
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-3 mt-6 " >
                        <div className="card mb-4 border box  ">
                        <img src={JaquareLaguna} className="card-img-top "   alt="JaquareLaguna" />
                            <div className="card-body">
                                <h5 style={{ textAlign: "center", color: "Gray	"}} className="card-text">Languna</h5>
                                
<div className="d-flex justify-content-between align-items-center">
                                
                           
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            {/* style={{textAlign: "center"}} */}
                            <div className="col-md-3" >
                        <div className="card mb-4 border box">
                        <img src={JaquareBidaspa} className="card-img-top"   alt="JaquareBidaspa" />
                            <div className="card-body">
                                <h5 style={{ textAlign: "center", color: "Gray	"}} className="card-text">Badispa</h5>
                   
                                <div className="d-flex justify-content-between align-items-center">
                                </div>
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-3 mt-6 " >
                        <div className="card mb-4 border box  ">
                        <img src={JContinental} className="card-img-top "   alt="JContinental" />
                            <div className="card-body">
                                <h5 style={{ textAlign: "center", color: "Gray	"}} className="card-text">Continental Series</h5>
                              
                            </div>
                            </div>
                               
                            </div>
                            </div>
                            </div>
                            </div>
                            <hr/> 
                            <div className="album py-0">
        
        <div className="container">

            <div className="row">
           
            <div className="col-md-6">
                    
                    <div className="card-body">
                    <input type="text" class="form-control" placeholder="Search All Products" name="location" 
                    onChange ={(e) => {
                      setsearchTerm(e.target.value);
                    }}
                    />
                           
                      
                
                    </div>

                  
                </div>
         
                </div>
              
                </div>
                </div>

<div className="album py-0">
    
    <div className="container">

        <div className="row">
        {allproducts.filter((val)=>{
  if (setsearchTerm === ""){
    return val;
  } else if (
    val.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    val.dis.toLowerCase().includes(searchTerm.toLowerCase()) || 
    val.stock.toLowerCase().includes(searchTerm.toLowerCase())
  
   

  ){
    return val;
  }
}).slice(pagination.start, pagination.end).map((Doctor) => { return (
   
        <div className="col-md-4 mt-4" >
                    <div className="card mb-4 border-White box shadow">
                    <img  className="card-img-top" src={Doctor.img}   alt="Singer" />
                        <div className="card-body">
                            
                            <h6 className="card-text"> <b>{ Doctor.title} </b></h6>
<p className="card-text mb-2">{Doctor.dis}</p>
<p className="card-text mb-2">{Doctor.stock}</p>
                            
                            <a href="tel: +91 9734644653"  className="btn btn- btn-secondary">  Call Now  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
<path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z"/>
</svg>  +91 9734644653</a> 
                            <div className="d-flex justify-content-between align-items-center">
                          
                                    {/* <button type="button" className="btn btn btn-dark">
                                    {website1.workf} 
                                    </button>  */}

                                   
                                
                          
                        </div>
                        </div>
                        </div>
                           
                        </div>
                            )})} 
                        </div>
                        <PaginationOld showPerPage={showPerPage} 
            onPaginationChange={onPaginationChange}
            total={allproducts.length}
            />
                        </div>
                        </div>
                            <div className="album py-4">
        
        <div className="container">

            <div className="row">
       
            <div className="col-md-6 mt-8" >

            <h6 className="card-text"> <b>Best Tiles & Marbel</b></h6>
                                <p className="card-text">
                                A spectacular mirror like effect is what you get in this superior finish. The highly polished look is achieved with a thicker coat of glaze, enhancing image sharpness and giving you a rich and true color experience.</p>
                                <button type="button" className="btn btn btn-light">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{textAlign: "center", color: "DarkOrange	"}} fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg>   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{textAlign: "center", color: "DarkOrange	"}} fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg>   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{textAlign: "center", color: "DarkOrange	"}} fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg>   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{textAlign: "center", color: "DarkOrange	"}} fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{textAlign: "center", color: "DarkOrange	"}} fill="currentColor" class="bi bi-star" viewBox="0 0 16 16">
  <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/>
</svg>
                                        </button>
                                
           
                                
                                <div className="d-flex justify-content-between align-items-center">
                               
                        
                            </div>
                               
                            </div>
                            <div className="col-md-6 mt-8" >
                            <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active ">
      <img src={Bathroom} class="d-block w-100" alt="Bathroom"/>
    </div>
    <div class="carousel-item ">
      <img src={Jaquare} class="d-block w-100" alt="Jaquare"/>
    </div>
    <div class="carousel-item">
      <img src={Kitchen} class="d-block w-100" alt="Kitchen"/>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>

                            {/* <img src={Bathroom} className="card-img-top"   alt="Bathroom" /> */}
                                
                                <div className="d-flex justify-content-between align-items-center">
                         
                            </div>
                               
                            </div>
                            </div>
                            </div>
                            </div>

<hr/>
<div className="album py-4">
        
        <div className="container">

            <div className="row">
       
            <div className="col-md-6 mt-8" >
            <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src={Saniraey} class="d-block w-100" alt="Saniraey"/>
      <div class="carousel-caption d-none d-md-block">
        <h5  style={{ color: "white	"}} >SanitaryWare</h5>
        <p >Queen's Prime. Stock Available Order Now</p>
      </div>
    </div>
   
    <div class="carousel-item">
      <img src={SaniraeyWare} class="d-block w-100" alt="SaniraeyWare"/>
      <div class="carousel-caption d-none d-md-block">
        <h5  style={{ color: "white	"}} > SanitaryWare</h5>
        <p> Fonte. Stock Available Order Now</p>
      </div>
    </div>
    <div class="carousel-item">
      <img src={Newti} class="d-block w-100" alt="Newti"/>
      <div class="carousel-caption d-none d-md-block">
        <h5  style={{ color: "white	"}} >SanitaryWare</h5>
        <p style={{ color: "red	"}}> Out of Stock </p>
      </div>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
           
                            {/* <img src={Bathroom2} className="card-img-top"   alt="Bathroom2" /> */}
                                
                                <div className="d-flex justify-content-between align-items-center">
                               
                        
                            </div>
                               
                            </div>
                            <div className="col-md-6 mt-8" >
                              
            <h6 className="card-text"> <b>Best Sanitary Ware</b></h6>
                                <p className="card-text">
                                Experience the opulence at Jaquar Orientation Centre
</p>
                                <button type="button" className="btn btn btn-light">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{textAlign: "center", color: "DarkOrange"}} fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg>   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{textAlign: "center", color: "DarkOrange"}} fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg>   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{textAlign: "center", color: "DarkOrange"}} fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg>  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"  style={{textAlign: "center", color: "DarkOrange"}} fill="currentColor" class="bi bi-star-half" viewBox="0 0 16 16">
  <path d="M5.354 5.119 7.538.792A.516.516 0 0 1 8 .5c.183 0 .366.097.465.292l2.184 4.327 4.898.696A.537.537 0 0 1 16 6.32a.548.548 0 0 1-.17.445l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256a.52.52 0 0 1-.146.05c-.342.06-.668-.254-.6-.642l.83-4.73L.173 6.765a.55.55 0 0 1-.172-.403.58.58 0 0 1 .085-.302.513.513 0 0 1 .37-.245l4.898-.696zM8 12.027a.5.5 0 0 1 .232.056l3.686 1.894-.694-3.957a.565.565 0 0 1 .162-.505l2.907-2.77-4.052-.576a.525.525 0 0 1-.393-.288L8.001 2.223 8 2.226v9.8z"/>
</svg> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{textAlign: "center", color: "DarkOrange"}} fill="currentColor" class="bi bi-star" viewBox="0 0 16 16">
  <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/>
</svg>
                                        </button>
                          

                      
                                
                                <div className="d-flex justify-content-between align-items-center">
                         
                            </div>
                               
                            </div>
                            </div>
                            </div>
                            </div>
                
            <Mapg/>  
      
<div class="p-3 mb-0 bg-secondary text-white">

            
                    <h1 className="mb-3"><center>Request CallBack</center></h1>
                           
                  <AddBook/>
                    </div>
                    <div class="p-2 mb-0 bg-dark text-white">
                      <br/>
                      <div className="album py-0">
        
        <div className="container " >

            <div className="row ">
       
            <div className="col-md-7" >
              <p className="mb-1"> <small>EXPLORE OUR WIDE RANGE OF CATALOGUE</small></p>
              <h1> Download Catalogue</h1>
            </div>
            <div className="col-md-5" >
              <p className="mb-1"> <small>If you'd like to view our latest product catalogue you can download it here.</small></p>
              <a href={Pdf} className="btn btn-outline-light mb-2" >Download Catalogue</a>
            </div>
            
            </div>
            </div>
                      <br/>
                    </div>
                    </div>
                           
</>
);
}

export default Main;