
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
  apiKey: "AIzaSyDZ6iDHABxBExZTJxLxt7BU59tNQdPZlwE",
  authDomain: "clasik-tiles-a7565.firebaseapp.com",
  projectId: "clasik-tiles-a7565",
  storageBucket: "clasik-tiles-a7565.appspot.com",
  messagingSenderId: "341424874604",
  appId: "1:341424874604:web:e2541a934bab5a146fa449",
  measurementId: "G-9SNSPJ74X5"
 
};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;