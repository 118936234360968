import React from 'react';

import {Link} from 'react-router-dom';
import No1Marbel from '../images/HEAD2.jpg';
import Heading from '../images/tile3.jpg';
import No1Marbel1 from '../images/til.jpg';
import No1Marbel2 from '../images/tilby.jpg';
import Navbar from '../inc/Navbar';
import ScrollToTop from "react-scroll-to-top";

function Marbel(props) {
    return (
<>
<Navbar/>
<div className="album py-0">
        
        <div className="container " >

            <div className="row ">
       
            <div className="col-md-5" >
              <h5>Clasik Tiles and Marbel</h5>
            </div>
            </div>
            </div>
            </div>
            <br/>
            <ScrollToTop smooth top="100" color="Orange"/>
<div id="carouselExampleFade" class="carousel slide carousel-fade" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src={No1Marbel} class="d-block w-100" alt="No1Marbel"/>
    </div>
    <div class="carousel-item">
      <img src={Heading} class="d-block w-100" alt="Heading"/>
    </div>
    <div class="carousel-item">
      <img src={No1Marbel1} class="d-block w-100" alt="No1Marbel1"/>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
<div class="p-3 mb-2 bg-light text-dark">
<div className="album py-4">
        
        <div className="container">

            <div className="row">
       
            <div className="col-md-6 mt-8" >
                <div className="nisha">
            <img src={No1Marbel2} className="image"   alt="No1Marbel2" />
            <div className="middle">
    <div className="text"><h3 style={{color: "blue"}}>High Quality Marbel</h3>
    </div>

  </div>
           
            </div>
                                <div className="d-flex justify-content-between align-items-center">
                               
                        
                            </div>
                               
                            </div>
                            <div className="col-md-6 mt-8" >
             
                            <h6 className="card-text"> <b>CLASIK TILES AND MARBEL</b></h6>
                                <p className="card-text">
                                The best marble is available at Clasik Tiles & Marble. </p>
                            
           
                                
                                <div className="d-flex justify-content-between align-items-center">
                         
                            </div>
                               
                            </div>
                            </div>
                            </div>
                            </div>
                            </div>
{/* <img src={No1Marbel} class="img-floud" width="1349" hieght="600" alt="No1Marbel"></img> */}
</>
    );
}

export default Marbel;