import React from 'react';

import {Link} from 'react-router-dom';
import Navbar from '../inc/Navbar';

function Product(props) {
    return (
<>
<Navbar/>

</>
);
}

export default Product;