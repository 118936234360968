import React from 'react';

import './App.css';
import {BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom';

import Main from "./components/pages/Main";
import Product from "./components/pages/Product";
import Outd from "./components/pages/Outd";
import Footer from "./components/inc/Footer";
import Marbel from "./components/pages/Marbel";
import About from "./components/pages/About";
import Bests from "./components/pages/Bests";
import Contact from "./components/pages/Contact";
import Jaqu from "./components/pages/Jaqu";
import Floor from "./components/pages/Floor";
import Wall from "./components/pages/Wall";



function App() {
  return (
    <Router>
    
      <div>
  {/* <Navbar/> */}
       
        
        <Routes>

        <Route axact path="/" element={<Main/>}/>
        <Route axact path="/Product" element={<Product/>}/>
        <Route axact path="/out-door-tiles" element={<Outd/>}/>
        <Route axact path="/marbel" element={<Marbel/>}/>
        <Route axact path="/about" element={<About/>}/>
        <Route axact path="/BestSeller" element={<Bests/>}/>
        <Route axact path="/Contact" element={<Contact/>}/>
        <Route axact path="/jaquar-sanitary-ware" element={<Jaqu/>}/>
        <Route axact path="/Floor" element={<Floor/>}/>
        <Route axact path="/wall" element={<Wall/>}/>
    
    </Routes>
    <Footer/>
  
    </div>

    </Router>
  );
}

export default App;
