import React from "react";
import {Link} from 'react-router-dom';
import Navbar from '../inc/Navbar';
import './Bests.css'
import BestSeller from '../images/headbest.jpg';
import Bestseller from '../images/besthead3.jpg';
import Bestseller1 from '../images/be2.jpg';
import Bestseller2 from '../images/be3.jpg';
import Bestseller3 from '../images/be4.jpg';
import Bestseller4 from '../images/be5.jpg';
import Bestseller5 from '../images/be12.jpg';
import ScrollToTop from "react-scroll-to-top";

function Marbel(props) {
    return (
<>
<Navbar/>
<div className="album py-0">
        
        <div className="container " >

            <div className="row ">
       
            <div className="col-md-5" >
              <h5>Clasik Tiles and Marbel</h5>


            </div>
            </div>
            </div>
            </div>
           
<br/>
<ScrollToTop smooth top="100" color="Orange"/>
<div id="carouselExampleFade" class="carousel slide carousel-fade" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src={BestSeller} class="d-block w-100" alt="BestSeller"/>
    </div>
    <div class="carousel-item">
      <img src={Bestseller} class="d-block w-100" alt="Bestseller"/>
    </div>
    
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>

<div class="p-0 mb-2 bg-light text-white">
  <br/>
<marquee direction ="up" height="300%"><center><h1 style={{color:"darkblue"}}>Our Best Seller Product</h1></center> </marquee>

</div>
<br/>
<div className="album py-2">
        
        <div className="container " >

            <div className="row ">
       
            <div className="col-md-4 mt-8 " >
                        <div className="card mb-4 border-White box ">
                       
                            <div className="nisha1">
                        <img src={Bestseller1} className="card-img-top image1"   alt="Bestseller1" />
                        <div className="middle1">
    <div className="text" style={{color: "purple"}}> @Best Seller #CLASIK TILES AND MARBEL
    </div>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{textAlign: "center", color: "DarkOrange	"}} fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg>   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{textAlign: "center", color: "DarkOrange	"}} fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg>   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{textAlign: "center", color: "DarkOrange	"}} fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg>   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{textAlign: "center", color: "DarkOrange	"}} fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg>  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{textAlign: "center", color: "DarkOrange	"}} fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg>
  </div>
           </div>
                            <div className="card-body">
                                
                                <h6  style={{ color: "DarkSlateBlue	"}} className="card-text"> <b>Clasik Tiles & Marbel</b></h6>
                             
                                <p className="card-text">@Best Seller</p>
                             
                        
                                <div className="d-flex justify-content-between align-items-center">
                               
                                       
                        
                              
                            </div>
                          
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-4 mt-6 " >
                        <div className="card mb-4 border-white box  ">
                        <div className="nisha1">
                        <img src={Bestseller2} className="card-img-top image1"   alt="Bestseller2" />
                        <div className="middle1">
    <div className="text" style={{color: "purple"}}> @BestSeller #CLASIK TILES AND MARBEL
    </div>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{textAlign: "center", color: "DarkOrange	"}} fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg>   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{textAlign: "center", color: "DarkOrange	"}} fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg>   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{textAlign: "center", color: "DarkOrange	"}} fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg>   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{textAlign: "center", color: "DarkOrange	"}} fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{textAlign: "center", color: "DarkOrange	"}} fill="currentColor" class="bi bi-star" viewBox="0 0 16 16">
  <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/>
</svg>
  </div>
           </div>
                            <div className="card-body">
                                <h6 style={{ color: "DarkSlateBlue	"}} className="card-text"><b>Clasik Tiles & Marbel</b></h6>
                                <p className="card-text">@Best Seller</p>
<div className="d-flex justify-content-between align-items-center">
                                
                           
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            {/* style={{textAlign: "center"}} */}
                            <div className="col-md-4" >
                        <div className="card mb-4 border-white box">
                        <div className="nisha1">
                        <img src={Bestseller3} className="card-img-top image1"    alt="Bestseller3" />
                        <div className="middle1">
    <div className="text" style={{color: "purple"}}> @BestSeller #CLASIK TILES AND MARBEL
    </div>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{textAlign: "center", color: "DarkOrange	"}} fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg>   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{textAlign: "center", color: "DarkOrange	"}} fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg>   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{textAlign: "center", color: "DarkOrange	"}} fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg>   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{textAlign: "center", color: "DarkOrange	"}} fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{textAlign: "center", color: "DarkOrange	"}} fill="currentColor" class="bi bi-star" viewBox="0 0 16 16">
  <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/>
</svg>
  </div>
  </div>
                            <div className="card-body">
                                <h6 style={{ color: "DarkSlateBlue		"}} className="card-text"><b>Clasik Tiles & Marbel</b></h6>
                                <p   className="card-text">@Best Seller</p>
                                <div className="d-flex justify-content-between align-items-center">
                                   
                               
                               
                           
                      
                                     
                                      
                                       
                              
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-4 mt-6 " >
                        <div className="card mb-4 border-white box  ">
                        <div className="nisha1">
                        <img src={Bestseller4} className="card-img-top image1"   alt="Bestseller4" />
                        <div className="middle1">
    <div className="text" style={{color: "purple"}}> @BestSeller #CLASIK TILES AND MARBEL
    </div>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{textAlign: "center", color: "DarkOrange	"}} fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg>   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{textAlign: "center", color: "DarkOrange	"}} fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg>   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{textAlign: "center", color: "DarkOrange	"}} fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg>   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{textAlign: "center", color: "DarkOrange	"}} fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{textAlign: "center", color: "DarkOrange	"}} fill="currentColor" class="bi bi-star" viewBox="0 0 16 16">
  <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/>
</svg>
  </div>
           </div>
                            <div className="card-body">
                                <h6 style={{ color: "DarkSlateBlue	"}} className="card-text"><b>Clasik Tiles & Marbel</b></h6>
                                <p className="card-text"> @Best Seller</p>
<div className="d-flex justify-content-between align-items-center">
                                
                           
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-4 mt-6 " >
                        <div className="card mb-4 border-white box  ">
                        <div className="nisha1">
                        <img src={Bestseller5} className="card-img-top image1"   alt="Bestseller2" />
                        <div className="middle1">
    <div className="text" style={{color: "purple"}}> @BestSeller #CLASIK TILES AND MARBEL
    </div>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{textAlign: "center", color: "DarkOrange	"}} fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg>   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{textAlign: "center", color: "DarkOrange	"}} fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg>   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{textAlign: "center", color: "DarkOrange	"}} fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg>   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{textAlign: "center", color: "DarkOrange	"}} fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{textAlign: "center", color: "DarkOrange	"}} fill="currentColor" class="bi bi-star" viewBox="0 0 16 16">
  <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/>
</svg>
  </div>
           </div>
                            <div className="card-body">
                                <h6 style={{ color: "DarkSlateBlue	"}} className="card-text"><b> Clasik Tiles & Marbel </b></h6>
                                <p className="card-text"> @Best Seller</p>
<div className="d-flex justify-content-between align-items-center">
                                
                           
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            </div>
                            </div>
                            </div>
</>

);
}

export default Marbel;