import React from 'react';

import {Link} from 'react-router-dom';

function Main(props) {
    return (
<>
<div className="album py-4">
        
        <div className="container">

            <div className="row">
       
            
 
           
         

<iframe  className="iframe-fluid" width="1100" height="436"  id="gmap_canvas" src="https://maps.google.com/maps?width=700&amp;height=440&amp;hl=en&amp;q=Clasik%20tiles%20%26%20marbel%20Karimpur%2C%20Natidangamore+(Title)&amp;ie=UTF8&amp;t=&amp;z=10&amp;iwloc=B&amp;output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>


</div>

</div>
</div>


</>
);
}

export default Main;