
import React from 'react';
import {Link} from 'react-router-dom';

import Tilesm1 from '../images/M4.jpg';
import Tilesm2 from '../images/MF2.jpg';
import Tilesm3 from '../images/12345.jpg';
import Tilesm4 from '../images/heading.jpg';


function Navbar(props) {
    return (

        
        <div>

<div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src={Tilesm1} class="d-block w-100" className="img-fluid" width="1350" height="600" alt="Tilesm1"/>
    </div>
    <div class="carousel-item">
      <img src={Tilesm2} class="d-block w-100"  className="img-fluid" width="1350" height="600" alt="Tilesm1"/>
    </div>
    <div class="carousel-item">
      <img src={Tilesm3} class="d-block w-100" className="img-fluid" width="1350" height="600"  alt="Tilesm3"/>
    </div>
    <div class="carousel-item">
      <img src={Tilesm4} class="d-block w-100" className="img-fluid" width="1350" height="600" alt="Tilesm4"/>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>

</div>
    
  );
}

export default Navbar;