import React, { useState, useEffect }  from 'react';

import {Link, useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
// import PaginationOld from './PaginationOld';
import {db} from '../../firebase'
import Navbar from '../inc/Navbar';
import Walltiles from '../images/wa.jpg';
import Walltiles1 from '../images/mainwall.jpg';
import Walltiles2 from '../images/mainwall2n.jpg';
import ScrollToTop from "react-scroll-to-top";

function Floor(props) {
    const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(50);
    const [pagination, setPagination] =useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start,  end) =>{
        setPagination({start: start, end: end});
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [walltiles, setWalltiles] = useState([])
    const navigate = useNavigate()

    const walltilesCollectionRef = collection(db, "walltiles");
    useEffect(() => {

        const getWalltiles = async () => {
            const data = await getDocs(walltilesCollectionRef);
            setWalltiles(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
        }

        getWalltiles()
    }, [])
    return (
        
<>
<Navbar/>
<div className="album py-0">
        
        <div className="container " >

            <div className="row ">
       
            <div className="col-md-5" >
              <h5> Clasik Tiles and Marbel</h5>
            </div>
            </div>
            </div>
            </div>
<br/>
<div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src={Walltiles1} class="d-block w-100" className="img-fluid" width="1350" height="600" alt="Walltiles1"/>
    </div>
    <div class="carousel-item">
      <img src={Walltiles2} class="d-block w-100"  className="img-fluid" width="1350" height="600" alt="Walltiles2"/>
    </div>
    <div class="carousel-item">
      <img src={Walltiles} class="d-block w-100" className="img-fluid" width="1350" height="600"  alt="Walltiles"/>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
<ScrollToTop smooth top="100" color="Orange"/>

{/* <img src={Walltiles} class="img-fluid" width="1350" height="600"  alt="Walltiles"></img> */}

<div className="album py-1">
        
            <div className="container">

                <div className="row">
               
                <div className="col-md-6">
                        
                        <div className="card-body">
                        <input type="text" class="form-control" placeholder="Search Wall Tiles" name="location" 
                        onChange ={(e) => {
                          setsearchTerm(e.target.value);
                        }}
                        />
                                <div className="d-flex justify-content-between align-items-center">
                                   
                                    
                            

                            </div>
                          
                    
                        </div>

                      
                    </div>
             
                    </div>
                  
                    </div>
                    </div>

<div className="album py-1">
        
        <div className="container">

            <div className="row">
            {walltiles.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } else if (
        val.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        val.dis.toLowerCase().includes(searchTerm.toLowerCase())

       
  
      ){
        return val;
      }
    }).slice(pagination.start, pagination.end).map((wall) => { return (
       
            <div className="col-md-4 mt-4" >
                        <div className="card mb-4 border-White box shadow">
                        <img  className="card-img-top" src={wall.img}   alt="Walltiles" />
                            <div className="card-body">
                                
                                <h6 className="card-text"> <b> { wall.title} </b></h6>
<p className="card-text mb-2">{wall.dis}</p>
<p className="card-text mb-2">{wall.stock}</p>
                         
                                <a href="tel: +91 9734644653"  className="btn btn- btn-secondary">  Book Now  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z"/>
</svg>  +91 9734644653</a> 
                                <div className="d-flex justify-content-between align-items-center">
                              
                                        {/* <button type="button" className="btn btn btn-dark">
                                        {website1.workf} 
                                        </button>  */}

                                       
                                    
                              
                            </div>
                            </div>
                            </div>
                               
                            </div>
                                )})} 
                            </div>
                            {/* <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={walltiles.length}
                /> */}
                            </div>
                            </div>
</>

);
}

export default Floor;