import React from 'react';
// import './Footer.css';
import {Link} from 'react-router-dom';
import Pdf from "../images/tiles.pdf";
import LogoFooter from "../images/CLASIKFOOTER.jpg"
import MRINDIANCODER from "../images/mr.indiancoder.jpg";
import "./Footer.css";

function Footer() {
    return(
        
    
     <div className="backc">
         <br></br>
         <br/>
        
    <div className="album py-3">

        <div className="container">

            <div className="row">
            <div className="col-md-3">
              
                 <h6>Call</h6>
                   <hr/>
                    
                     <ul className="list-unstyled">
                    
                   
<a href="tel: +91 9734644653"  className="btn ">    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z"/>
</svg> +91 9734644653*</a>
<br/>
<br/>

          </ul>
          </div>

          <div className="col-md-3">
                     <h5>Product</h5>
                     <hr/>
                     <ul class="list-unstyled">
                    
                       <Link to ="/marbel">
            <li className="mb-2"><small>Marbel</small></li></Link>
            <Link to="/out-door-tiles">
            <li className="mb-2"><a><small>Outdoor Tiles</small></a></li></Link>
           
            <Link to="/wall">
            <li className="mb-2"><a ><small>Wall Tiles</small></a></li></Link>
            <Link to="/Floor">
            <li className="mb-2"><a ><small>Living Room Floor Tiles</small></a></li></Link>
            
            
          </ul>
                     {/* <div><Link to="/">Career </Link></div>
                     <div><Link to="/">Blog</Link></div>
                     <div><Link to="/">Uniaone</Link></div> */}
                 </div>
                 <div className="col-md-3">
                     <h6>About Us</h6>
                     <hr/>

                     <ul class="list-unstyled">
                     <Link to="/about">
                     <li className="mb-2"><small>About </small></li></Link>
                     <Link to="/contact">
                     <li className="mb-2"><small>Contact </small></li></Link>

                     </ul>
                 </div>
                 <div className="col-md-3">
                 <h6>Quick Links</h6>
                   <hr/>
                     <ul className="list-unstyled">
                      
                            <li className="mb-2"><small><Link to="/jaquar-sanitary-ware" href="">Jaqure Product</Link></small></li>
                            <li className="mb-2"><small><Link to="/BestSeller" href="">Best Seller</Link></small></li>
                            <a href={Pdf} >Download Catalogue</a>
                         
              
            
          </ul>
          
          </div>
       
  

        
          
                 </div>
                 </div>
                 </div>
               
               
                 <br></br><br/>

    <div className="album py-3">
        
        <div className="container">

            <div className="row">
            <div className="col-md-4">
      <Link to="/">
                 <img src={LogoFooter } class="img-fluid" alt="Logofooter"/> </Link>
                 </div>

                 <div className="col-md-4">
                 <p> Copyright © 2022, CLASIK TILES AND MARBEL </p>
                  
                 </div>
                 <div className="col-md-4">
              <small style={{ color: "white" }}> Development & Design : <a style={{ color: "white" }} href="https://www.mrindiancoder.com/" target="_blank" rel="noopener noreferrer"> www.mrindiancoder.com</a> </small>
              <small>  <a href="https://www.mrindiancoder.com/" target="_blank" rel="noopener noreferrer"> </a> </small>
              <a href="https://www.mrindiancoder.com/" target="_blank" rel="noopener noreferrer"> <img src={MRINDIANCODER} width="85" height="30" alt="mr.indiancoder" /> </a>
            </div>
                 
                 </div>
                 </div>
                 </div>
                <br></br>

             </div>
             
            
         
         
     
    );
}
export default Footer;