import React from "react";
import {Link } from "react-router-dom"
import Navbar from "../inc/Navbar";
import Bathh from '../images/jaa.jpg';
import ArcJaquare from '../images/Arc.jpeg';
import JaquareLaguna from '../images/Laguna.jpeg';
import JaquareBidaspa from '../images/Bidaspa.png';
import JContinental from '../images/Continental.jpeg';
import ScrollToTop from "react-scroll-to-top";

function Jaqu (props){
    return(
<>
<Navbar/>
<div className="album py-0">
        
        <div className="container " >

            <div className="row ">
       
            <div className="col-md-5" >
              <h5>Clasik Tiles and Marbel</h5>
            </div>
            </div>
            </div>
            </div>
            <br/>
            <ScrollToTop smooth top="100" color="Orange"/>
<img src={Bathh} class="img-fluid" width="1350" height="600"  alt="Outdoor"></img>


<div className="album py-4">
        
        <div className="container " >

            <div className="row ">
       
            <div className="col-md-3 mt-8 " >
                        <div className="card mb-4 border-White box ">
                            <Link to="/outd">
                        <img src={ArcJaquare} className="card-img-top"   alt="ArcJaquare" />
                        </Link>
                            <div className="card-body">
                                
                                <h5  style={{textAlign: "center",color: "Gray	"}} className="card-text">Arc</h5>
                                <Link to="/outd">
                       
                             
                        
                                <div className="d-flex justify-content-between align-items-center">
                               
                                       
                        
                              
                            </div>
                            </Link>
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-3 mt-6 " >
                        <div className="card mb-4 border box  ">
                        <img src={JaquareLaguna} className="card-img-top "   alt="JaquareLaguna" />
                            <div className="card-body">
                                <h5 style={{ textAlign: "center", color: "Gray	"}} className="card-text">Languna</h5>
                                
<div className="d-flex justify-content-between align-items-center">
                                
                           
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            {/* style={{textAlign: "center"}} */}
                            <div className="col-md-3" >
                        <div className="card mb-4 border box">
                        <img src={JaquareBidaspa} className="card-img-top"   alt="JaquareBidaspa" />
                            <div className="card-body">
                                <h5 style={{ textAlign: "center", color: "Gray	"}} className="card-text">Badispa</h5>
                   
                                <div className="d-flex justify-content-between align-items-center">
                                </div>
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-3 mt-6 " >
                        <div className="card mb-4 border box  ">
                        <img src={JContinental} className="card-img-top "   alt="JContinental" />
                            <div className="card-body">
                                <h5 style={{ textAlign: "center", color: "Gray	"}} className="card-text">Continental Series</h5>
                                <div className="d-flex justify-content-between align-items-center">
                                </div>
                            </div>
                            </div>
                               
                            </div>
                            </div>
                            </div>
                            </div>
                            <hr/>
</>
    );
}

export default Jaqu;