import React from 'react';
import './Abnout.css'
import {Link } from 'react-router-dom';
import Navbar from '../inc/Navbar';
import PintuBabu from '../images/pintuBabu.jpg';

function About(props) {
    return (
<>
<Navbar/>
      <br/>
      <br/>
<div class="p-3 mb-2 bg-light text-dark">
<div className="album py-4">
        
        <div className="container">

            <div className="row">
       
            <div className="col-md-6 mt-8" >
                <div className="nisha">
            <img src={PintuBabu} className="image"   alt="PintuBabu" />
            <div className="middle">
    <div className="text"> <h5 style={{color: "white"}}>I'm Pintu Kumar Paul, Founder of Clasik Tiles and Marbel</h5>
    </div>
  </div>
           
            </div>
                                <div className="d-flex justify-content-between align-items-center">
                               
                        
                            </div>
                               
                            </div>
                            <div className="col-md-6 mt-8" >
             
                            <h6 className="card-text"> <b>About Pintu Kumar Paul</b></h6>
                                <p className="card-text">
I'am Pintu Kumar Paul , Founder of CLASIK TILES AND MARBEL.(Karimpur, Natidangamore, Nadia)</p>
                            
           
                                
                                <div className="d-flex justify-content-between align-items-center">
                         
                            </div>
                               
                            </div>
                            </div>
                            </div>
                            </div>
                            </div>
</>

    );
}
export default About;