import React from 'react';
import {Link} from 'react-router-dom';
import Pdf from "../images/tiles.pdf";
import Logo from "../images/CLASIKLOGO.jpg";

function Navbar(props) {
    return (

        
        <div>
<div className="fixed-top">
<nav className="navbar navbar-expand-lg navbar-dark bg-dark">
{/* <nav className="navbar navbar-expand-lg navbar-white  shadow bg-secondary"> */}
  <div className="container-fluid">
    <Link to="/">
  <img src={Logo} className="img-fluid me-5"  width="140" height="40" alt="Logos"/>
  </Link>

    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <Link to="/" className="nav-link active" aria-current="page" ><b>HOME</b></Link>
        </li>
        <li className="nav-item">
          <Link to="/about" className="nav-link active" aria-current="page" ><b><small>ABOUT</small></b></Link>
        </li>
       
        <li className="nav-item">
          <Link to="/BestSeller" className="nav-link active" aria-current="page" ><b><small>BEST SELLER</small></b></Link>
        </li>
 
        
         <li className="nav-item">
          <Link to="/marbel" className="nav-link active" aria-current="page"><b><small>MARBEL</small> </b></Link>
        </li>
        <li className="nav-item">
          <Link to="/jaquar-sanitary-ware" className="nav-link active" aria-current="page"><b><small>JAQURE </small> </b></Link>
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          <b><small> TILES PRODUCT</small> </b>
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><Link to="/out-door-tiles" className="dropdown-item" >Outdoor Tiles</Link></li>
            <li><Link to="/Floor" className="dropdown-item"  href="#">Living Room Floor Tiles</Link></li>
            <li><hr className="dropdown-divider"/></li>
            <li><Link to="/wall" className="dropdown-item" href="#">All Wall Tiles</Link></li>
          </ul>
        </li>
        <li className="nav-item">
          <Link to="/Contact" className="nav-link active" aria-current="page" ><b> <small>CONTACT</small></b></Link>
        </li>
       
      </ul>
     <a href={Pdf} className="btn btn-outline-light mb-2" >Download Catalogue</a>
    </div>
  </div>
</nav>
</div>

</div>
    
  );
}

export default Navbar;